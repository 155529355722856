import React from 'react';

import { DATA_HOOKS } from './dataHooks';

import { classes } from './MembershipButton.st.css';
import { Button } from '../../../../common/components/Button';
import classname from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useUser } from '../../../../common/context/user/useUser';
import { useMembershipButtonPreferences } from './useMembershipButtonPreferences';
import { UserStatus } from '../../../../common/context/user/IUserContext';
import { useGroupV2 } from '../../contexts/Group/useGroupV2';

export interface MembershipButtonProps {
  className?: string;
  biOrigin?: string;
  label?: string;
}

export const MembershipButton = (props: MembershipButtonProps) => {
  const { t } = useTranslation();

  const { userActions, userStatus } = useUser();
  const group = useGroupV2();
  const buttonConfig = useMembershipButtonPreferences(group);
  if (!buttonConfig) {
    return null;
  }

  const { buttonLabelKey, priority } = buttonConfig;
  const disabled = userStatus === UserStatus.UPDATING;

  return (
    <div className={classname(classes.root, props.className || '')}>
      <Button
        fullWidth
        priority={priority}
        onClick={() =>
          userActions.changeGroupMembership(
            group.groupV2!,
            undefined,
            props.biOrigin,
          )
        }
        data-hook={DATA_HOOKS.membershipButton}
        disabled={disabled}
      >
        {props.label || t(buttonLabelKey)}
      </Button>
    </div>
  );
};

MembershipButton.displayName = 'MembershipButton';
